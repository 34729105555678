body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

.btn-primary{
  background-color: #0aa5cd;
  border-color: #0aa5cd;
  color: #ffffff;
  font-weight: 700;
}
.btn-primary:hover{
  background-color: #0aa5cd !important;
  border-color: #0aa5cd !important;
  color: #ffffff;
  font-weight: 700;
}
.btn-secondary{
  background-color: #17202d !important;
  border-color: #17202d !important;
  color: #ffffff;
  font-weight: 700;
}
.btn-secondary:hover{
  background-color: #17202d !important;
  border-color: #17202d !important;
  color: #ffffff;
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-primario {
  background-color: #0aa5cd !important;
  --bs-bg-opacity: 1;
}

.c-primario {
  color: #0aa5cd !important;
}

.navbar {
  height: 100px;
}

.navbar-expand-lg .navbar-nav .nav-link i {
  color: #ffffff;
  font-size: 23px;
  cursor: pointer;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}
.navbar-expand-lg .navbar-nav .nav-link:hover, .navbar-expand-lg .navbar-nav .nav-link i:hover{
  color: #17202d;
}

.navbar-nav .nav-link.active {
  border-bottom: 4px solid #17202d;
}

.logo {
  max-width: 200px;
  width: 100%;
  height: auto;
}
.mySwiperInicioProduct{
  height: 100% !important;
}
.mySwiper{
  height: 550px !important;
}
.swiper {
  width: 100%;
  height:  100% ;
}
.mySwiper{
  margin-bottom: 120px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.filtroInicio {
  margin-top: -240px;
  z-index: 99;
  position: absolute;
}
.filtroInicio .container{
  border-radius: 0.428rem;
}
.filtroInicio .form-select {
  height: 60px;
}

.filtroInicio .btn-primary {
  background-color: #17202d;
  height: 60px;
}

.card {
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, .125);
  border-radius: 0.428rem;
  overflow: hidden;
  transition: all .3s ease-in-out,background 0s,color 0s,border-color 0s;
}
.cardd{
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, .125);
  border-radius: 0.428rem;
  overflow: hidden;
  transition: all .3s ease-in-out,background 0s,color 0s,border-color 0s;
  padding: 1rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #5e5873;
}
.mySwiperInicioProduct .card{
  transition: all .3s ease-in-out,color 0s,border-color 0s;
  overflow: hidden;
}
.mySwiperInicioProduct img{
  display: block;
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
}
.card:hover{
  transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}
.mySwiperInicioProduct h5{
  font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mySwiperInicioProduct a{
  text-decoration: none;
}
.mySwiperInicioProduct h5:hover{
  color: #0aa5cd;
}
.mySwiperInicioProduct p{
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.5rem;
    font-size: .9rem;
}
.verProducto{
  background-color: #0aa5cd;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}
.comprarProducto{
  background-color: #17202d;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
}
.loginapp .verProducto, .loginapp .comprarProducto {
  font-size: 1rem !important;
}
.footer{
  background-color: #0aa5cd;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer a{
  text-decoration: none;
  text-align: center;
  color: #fff;
}
.loginApp{
  min-height: calc(100vh - 100px);
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.registrateLogin{
  text-align: center;
  text-decoration: none;
  margin: auto;
  display: block;
  margin-top: 1.5rem;
  color: #0aa5cd;
  font-weight: 700;
  cursor: pointer;
}
.loginApp .btn-primary{
  background-color: #0aa5cd;
  border-color: #0aa5cd;
  font-weight: 700;
}

.shop .card img{
  display: block;
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
}

.shop .card a{
  text-decoration: none;
}
.shop .card h5:hover{
  color: #0aa5cd;
}
.shop .card p{
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.5rem;
    font-size: .9rem;
}
.quitarfiltro{
  margin-bottom: .5rem;
}
.quitarfiltro i{
  margin-right: .6rem;
  cursor: pointer;
}
.limpiarTodo .btn-primary{
  background-color: #0aa5cd;
  border-color: #0aa5cd;
  font-weight: 600;
}
.titulo{
  cursor: pointer;
}
.item-price{
  color:#0aa5cd;
}
.descripcion{
  text-align: justify;
}
.comprar{
  font-weight: 600;
  width: 100%;
  max-width: 200px;
  height: 50px;
}

@media(max-width:1024px){
  .header .container{
    padding: 0 !important;
  }
  .navbar-collapse{
    background-color: #0aa5cd;
    width: 100%;
    position: absolute;
    top: 95px;
    left: 0;
    padding: 1rem;
    z-index: 99;
  }
  .navbar-nav .nav-link.active{
    background-color: #17202d;
  }
  .navbar-expand-lg .navbar-nav .nav-link{
    text-align: center;
  }
}

@media(max-width:767px){
  .mySwiper{
    margin-bottom: 550px;
  }
  .filtroInicio{
    margin-top: -600px;
  }
  .cerrarsesionmodal{
    left: 40%;
  }
  .caja-edit-product{
    grid-template-columns: 1fr !important;
  }
}
.badge{
  font-size: 10px;
  right: 30px;
}
.cerrarsesionmodal{
  position: absolute;
  background-color: #ffffff;
  z-index: 99;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, .125);
  border-radius: 0.428rem;
  overflow: hidden;
  transition: all .3s ease-in-out,background 0s,color 0s,border-color 0s;
  cursor: pointer;
}
.img-carrito{
  max-height: 200px;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card a{
  text-decoration: none;
}
.card a h5:hover{
  color: #0aa5cd !important; 
}
.caja-edit-product{
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, .125);
  border-radius: 0.428rem;
  overflow: hidden;
  transition: all .3s ease-in-out,background 0s,color 0s,border-color 0s;
  overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding-right: 0;
}
.caja-edit-product:hover{
  transform: translateY(-5px);
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
}
.caja-edit-product .card-body{
  border-right: 1px solid #ebe9f1;
  padding: .7rem;
}
.item-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 15px;
  padding: .7rem;
}
.item-img img{
  width: 200px;
}
.caja-edit-product h6{
  color: #0aa5cd;
  font-weight: 700;
}
.caja-edit-product .btn-primary{
  background-color: #0aa5cd;
  border-color: #0aa5cd;
}
.contador{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 40px;
  margin-top: 20px;
  border-radius: .425rem;
  overflow: hidden;
}
.contador .boton{
  width: 40px;
  height: 40px;
  background-color: #0aa5cd;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
.contador .total{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: #17202d;
  color: #ffffff;
  font-weight: 600;
}
.descripcion-carrito{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: .8rem;
  margin-top: 1rem;
}
.item-options{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .7rem;
}

.tabla-precio{
  overflow: hidden;
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.tabla-precio p{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Modal{
  width: 300px;
}